import _ from 'underscore'
import VaultType from '../accounts/VaultType'

/**
 *
 * @param vaultTypes The loaded vault types
 * @returns an object where the keys are collateral type and the value is the total for that type
 */
export function CollateralTotals(vaultTypes: VaultType[]) {
  // Sum up all the collateral totals
  const collateralAmountTotals = _.reduce(
    vaultTypes,
    (totals, vaultType) => {
      const newTotals = { ...totals }
      const existingCollateralTotal = totals[vaultType.collateral] || 0
      const newTotal =
        existingCollateralTotal +
        vaultType.collateralHeld.div(vaultType.lamportsPerCollateralUnit).toNumber()
      newTotals[vaultType.collateral] = newTotal
      return newTotals
    },
    {}
  )

  return collateralAmountTotals
}
