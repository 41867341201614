import { LAMPORTS_PER_SOL } from '@solana/web3.js'
import Image from 'next/image'
import Link from 'next/link'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
//@ts-ignore
import SolanaCoins from '../assets/homepage/coins-animated.svg'
import IconCoin from '../assets/homepage/icons/icon-coin.svg'
import IconDollar from '../assets/homepage/icons/icon-dollar.svg'
import IconSafeOpen from '../assets/homepage/icons/icon-safe-open.svg'
import IconScale from '../assets/homepage/icons/icon-scale.svg'
import IconWater from '../assets/homepage/icons/icon-water.svg'
import IconZero from '../assets/homepage/icons/icon-zero.svg'
import UshCoin from '../assets/homepage/ush-coin.svg'
import ZeroPercentOutline from '../assets/homepage/zero-percent-outline.svg'
import BigBrain from '../assets/logos/investors/square/big-brain.svg'
import Dcm from '../assets/logos/investors/square/dcm.svg'
import Pantera from '../assets/logos/investors/square/pantera.svg'
import Race from '../assets/logos/investors/square/race.svg'
import Shima from '../assets/logos/investors/square/shima.svg'
import SolanaVentures from '../assets/logos/investors/square/solana-ventures.svg'
import MainLayout from '../components/MainLayout'
import { numberWithCommas } from '../lib/Utils'
import VaultSystemState from '../on-chain/accounts/VaultSystemState'
import VaultType from '../on-chain/accounts/VaultType'
import { Collateral } from '../on-chain/utils/OracleAccounts'
import { CollateralTotals } from '../on-chain/utils/SummaryInfo'
import { RootState } from './_app'




export default function Home() {
  const vaultSystemState = useSelector(
    (state: RootState) => state.wallet.vaultSystemState
  ) as VaultSystemState

  const vaultTypes = useSelector((state: RootState) => state.wallet.vaultTypes) as VaultType[]

  const collateralAmountTotals = CollateralTotals(vaultTypes)

  return (
    <MainLayout>
      <Container className="mb-5 py-5">
        <Row className="align-items-center">
          <Col lg={{ span: 6, offset: 0 }} xl={{ span: 6, offset: 0 }}>
            <div className="my-5 px-3 px-md-5 text-center text-lg-start">
              <div className="py-1 py-lg-5 my-2 my-lg-5">
                <h1 className="homepage-preview-huge">
                  Never Sell Your <span className="text-primary">SOL</span>
                </h1>
                <p className="lead mb-4 mt-3">
                  {'Access your '}
                  <span className="text-primary">{"SOL's"}</span>
                  {' value today, without losing tomorrows upside'}
                </p>
                {/* <div className="col-lg-12 mx-auto">
                  <h4 className="fw-bold mb-4">
                    0% Interest - Instant Margin Access - Leverage Assets
                  </h4>
                </div> */}
                <div className="mt-5 d-sm-flex">
                  <Link href="/borrow" passHref>
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-lg px-5 gap-3 text-white mx-auto mx-lg-0"
                    >
                      LAUNCH APP
                    </button>
                  </Link>
                </div>
                <div className="col-lg-12 mt-5">
                  <Link href="https://discord.gg/qfqegrRWmw" passHref>
                    <a target="_blank">
                      <i className="mx-3 icon fa-brands fa-discord fa-2x"></i>
                    </a>
                  </Link>
                  <Link href="https://twitter.com/HedgeLabs" passHref>
                    <a target="_blank">
                      <i className="mx-3 icon fa-brands fa-twitter fa-2x"></i>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </Col>
          <Col className="text-center d-none d-lg-block">
            <Image src={SolanaCoins} layout="responsive" />
          </Col>
        </Row>
      </Container>
      <Container className="mb-5 py-5">
        <Row>
          <Col lg={{ span: 12, offset: 0 }}>
            <div className="text-center fw-light h5">A FEW OF OUR AMAZING INVESTORS</div>
            <Row>
              <Col lg={{ span: 6 }}>
                <Row>
                  <Col>
                    <Link href="https://race.capital/" passHref>
                      <a target="_blank">
                        <Image src={Race} alt="Race Capital Logo" />
                      </a>
                    </Link>
                  </Col>
                  <Col>
                    <Link href="https://www.bigbrain.holdings/" passHref>
                      <a target="_blank">
                        <Image src={BigBrain} alt="Big Brain Logo" />
                      </a>
                    </Link>
                  </Col>
                  <Col>
                    <Link href="https://panteracapital.com/" passHref>
                      <a target="_blank">
                        <Image src={Pantera} alt="Pantera Logo" />
                      </a>
                    </Link>
                  </Col>
                </Row>
              </Col>
              <Col lg={{ span: 6 }}>
                <Row>
                  <Col>
                    <Link href="https://solana.ventures/" passHref>
                      <a target="_blank">
                        <Image src={SolanaVentures} alt="Solana Ventures Logo" />
                      </a>
                    </Link>
                  </Col>
                  <Col>
                    <Link href="https://shima.capital/" passHref>
                      <a target="_blank">
                        <Image src={Shima} alt="Shima Capital Logo" />
                      </a>
                    </Link>
                  </Col>
                  <Col>
                    <Link href="https://www.dcm.com/" passHref>
                      <a target="_blank">
                        <Image src={Dcm} alt="DCM Ventures Logo Logo" />
                      </a>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <div className="">
        <Container className="pt-5">
          <Row>
            <Col className="text-center display-3 fw-light">
              <div>{'Yep, 0% Interest. Really.'}</div>
            </Col>
          </Row>
          <Row>
            <Col lg={{ span: 3 }}></Col>
            <Col lg={{ span: 6 }}>
              <div className="my-5 text-center">
                <Image src={ZeroPercentOutline} alt="Zero percent vector" />
              </div>
            </Col>
            <Col lg={{ span: 3 }}></Col>
          </Row>
        </Container>
      </div>
      <div className="">
        <Container className="mb-5 py-5">
          <Row>
            <Col lg={{ span: 6 }} className="text-center mb-5">
              <div className="fw-light">Total USH Supply</div>
              <div className="fw-bold display-3">
                {vaultSystemState
                  ? numberWithCommas(vaultSystemState.totalUshSupply.div(LAMPORTS_PER_SOL), 2)
                  : 'loading...'}
              </div>
              <div className="text-secondary text-center h3">USH</div>
            </Col>
            <Col lg={{ span: 6 }} className="text-center mb-3">
              <div className="fw-light">Total SOL Collateral</div>
              <div className="fw-bold display-3">
                {collateralAmountTotals[Collateral.SOL]
                  ? numberWithCommas(collateralAmountTotals[Collateral.SOL], 2)
                  : 'loading'}
              </div>
              <div className="text-secondary text-center h3">SOL</div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="py-5">
        <Row>
          <Col className="text-center display-5 fw-light">
            <div>{'Decentralized. Secure. Instant.'}</div>
          </Col>
        </Row>
        <Row className="my-5 text-center">
          <Col md>
            <Image src={IconZero} />
            <div className="h2">Create a Vault</div>
            <div>
              When you create a vault, only your wallet will have access to make deposits,
              withdrawals, take margin, or repay margin.
            </div>
          </Col>
          <Col md>
            <Image src={IconCoin} />
            <div className="h2">Deposit Collateral</div>
            <div>
              Deposit collateral (SOL) into your vault. You can withdraw these tokens at any time as
              long as you don&apos;t have debt against them.
            </div>
          </Col>
          <Col md>
            <Image src={IconDollar} />
            <div className="h2">Take Margin</div>
            <div>
              Take margin against your deposits to give you access to capital. You can use these
              funds for anything you want.
            </div>
          </Col>
        </Row>
      </Container>
      <div className="my-5 py-5">
        <Container className="py-5">
          <div className="row align-items-center py-5">
            <div className="col-lg-5 offset-lg-1 mb-5">
              <div className="fw-bold lh-1 mb-3 display-4">Flexible Liquidity</div>
              <p className="lead">
                Use your USH for day to day purchases or use it to multiply your crypto exposure at
                a fixed fee. We currently offer up to 6x exposure.
              </p>
              <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                <Link href="/borrow" passHref>
                  <Button variant="light" size="lg" className="px-4 me-md-2">
                    Get USH
                  </Button>
                </Link>
                <Link href="https://docs.hedge.so/" passHref>
                  <Button variant="outline-light" size="lg" className="px-4 me-md-2">
                    Learn More
                  </Button>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 offset-lg-1">
              <Row>
                <Col lg={{ span: 10, offset: 1 }}>
                  <div className=" py-5 px-4 text-center home-coin-card">
                    <div>
                      <div>
                        <Image src={UshCoin} width={170} height={170} alt="" />
                      </div>
                      <div className="display-6 my-3 fw-bold">USH</div>
                      <div className="d-flex d-flex justify-content-between mb-3">
                        <span>Secure:</span>
                        <span>Fully Backed</span>
                      </div>
                      <div className="d-flex d-flex justify-content-between mb-3">
                        <span>Collateral:</span>
                        <span>{'> 110%'}</span>
                      </div>
                      <div className="d-flex d-flex justify-content-between">
                        <span>Redeemable</span>
                        <span>Anytime @ $1</span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
      <Container className="mb-5 pb-5 text-center">
        <Row className="mb-2 mb-md-5">
          <Col md>
            <Image src={IconZero} />
            <div className="h2">Interest-Free loans</div>
            <div>
              Hedge offers 0% interest vaults, allowing you to indefinitely tap into the value of
              your assets.
            </div>
          </Col>
          <Col md>
            <Image src={IconCoin} />
            <div className="h2">Long-Term leverage</div>
            <div>
              Leveraging with Hedge enables you to keep your leverage positions open for longer for
              a fraction of the perp funding fees.
            </div>
          </Col>
          <Col md>
            <Image src={IconDollar} />
            <div className="h2">Pegged to $1</div>
            <div>USH is always redeemable for its underlying value, ensuring peg.</div>
          </Col>
        </Row>
        <Row>
          <Col md>
            <Image src={IconScale} />
            <div className="h2">110% collateral ratio</div>
            <div>
              Hedge offers vaults with collateral ratio as low as 110%, enabling up to 11x leverage.
            </div>
          </Col>
          <Col md>
            <Image src={IconSafeOpen} />
            <div className="h2">Collateral backed</div>
            <div>
              USH collateral is kept secure in vaults and is not staked or placed in other
              protocols.
            </div>
          </Col>
          <Col md>
            <Image src={IconWater} />
            <div className="h2">Efficient liquidations</div>
            <div>
              Protocol solvency is guaranteed by a stability pool that liquidates risky vaults.
              Anyone can participate to earn HEDGE tokens.
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="mb-5 py-5">
        <Row className="mb-2 mb-md-5">
          <Col>
            <div className="display-3 fw-bold text-center">Open a vault now</div>
          </Col>
        </Row>
        <Row className="mb-2 mb-md-5">
          <Col className="text-center">
            <Link href="/borrow" passHref>
              <button
                type="button"
                className="btn btn-outline-primary btn-lg px-5 text-white mx-auto"
              >
                LAUNCH APP
              </button>
            </Link>
          </Col>
        </Row>
      </Container>
    </MainLayout>
  )
}
